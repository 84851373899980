
import { Vue, Component } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import TableHeader from "@/components/tableHeader.vue";
import QrcodeVue from 'qrcode.vue'
import IbotechService from '@/services/ibotechService';
import UserAccess from "@/model/User/UserAccess";
import InventoryTableDesktop from "@/components/Inventory/InventoryTableDesktop.vue";
import Multiselect from "vue-multiselect";
import * as XLSX from 'xlsx';

@Component({
  name: "Vehicle",
  components: { InventoryTableDesktop, TableHeader, QrcodeVue, Multiselect },
  computed: {
    ...mapGetters(["getAllVehiclesData", "getUserAccess", "isIbotech", "getAllLocationsData"]),
  },
  methods: {
    ...mapActions(["getAllVehiclesFun", "getAllLocations"])
  }
})
export default class Vehicle extends Vue {
  readonly getUserAccess!: UserAccess;
  private getAllVehiclesData!: Array<object>;

  private getAllLocationsData!: Array<any>;
  private getAllLocations!: Function;

  private getAllVehiclesFun!: Function;

  perPage: number = 30;
  currentPage: number = 1;
  loading = false;
  plateNumber: string = "";
  filter: string = "";
  searchInTableFor: string = "";

  editVehicle: boolean = false;
  form = {
    plateNumber: "",
    dailyCost: "",
    vehicleType: "",
    notice: "",
    vehicleBarcode: "1"
  }
  bookForm = {
    plateNumber: "",
    id: "",
    notice: ""
  }
  vehicleReportForm = {
    from: "",
    to: "",
  }

  fields: Array<string | any> = [
    {
      key: "selected",
      sortable: false,
      label: this.translateTableLabel('selected')
    },
    {
      key: "plateNumber",
      sortable: false,
      label: this.translateTableLabel('plateNumber')
    },
    {
      key: "dailyCost",
      sortable: false,
      label: this.translateTableLabel('dailyCost')
    },
    {
      key: "vehicleType",
      sortable: false,
      label: this.translateTableLabel('vehicleType')
    },
    {
      key: "lastLocations",
      sortable: false,
      label: this.translateTableLabel('lastLocations'),
    },
    {
      key: "notice",
      sortable: false,
      label: this.translateTableLabel('notice'),
    },
    {
      key: "actions",
      sortable: false,
      label: this.translateTableLabel('actions')
    }
  ];

  value = "https://google.com";
  size = 300;

  created() {

    // Redirect to 404
    if(!this.getUserAccess.AccessIbotech.VIEW.vehicle) {
      this.$router.push({ name: 'NotFound' });
    }

    this.loading = true;

    // Get - Set Vehicle Data
    this.getAllVehiclesFun().then(() => {
      this.loading = false;
    });

    // Get - Set Location Data
    // -----------------------
    this.getAllLocations().then(() => {
    });

  }

  openModalNewVehicle(actions: any, data: any) {

    this.editVehicle = false;

    if(actions == 'edit') {
      this.editVehicle = true;
      this.form.plateNumber = data.item.plateNumber;
      this.form.dailyCost = data.item.dailyCost;
      this.form.vehicleType = data.item.vehicleType;
      this.form.notice = data.item.notice;
      this.form.vehicleBarcode = data.item.vehicleBarcode;
    }

    this.$bvModal.show('newVehicle');
  }

  async bookLocationToVehicle(plateNumber: any) {

    // Check if PlateNumber have alreadx two or more bookings in 24h
    const res = await IbotechService.getVehicle(plateNumber);

    if(res.status === 200) {
      if(res.data.lastLocations.length < 2) {

        // reset Book Form Values
        this.bookForm = {
          plateNumber: "",
          id: "",
          notice: ""
        }

        this.bookForm.plateNumber = plateNumber;
        this.$bvModal.show("bookLocationToVehicle");

      } else {

        // Success Message
        this.$bvToast.toast( (this.$t('ibotech_vehicle.infoRecordAlreadyDoneText')).toString(), {
          title: (this.$t('ibotech_vehicle.infoRecordAlreadyDoneTitle')).toString(),
          autoHideDelay: 1500,
          variant: 'info'
        });
      }

    } else {
      this.error();
    }
  }

  //
  resetVehicleValues() {

    this.$bvModal.hide("newVehicle");

    this.form = {
      plateNumber: "",
      dailyCost: "",
      vehicleType: "",
      notice: "",
      vehicleBarcode: "1"
    }
  }

  async submitNewVehicle() {
     if(this.isFormValid) {

       let res;

       //TODO: FEHLERMELDUNG AUSGEBEN WENN ES DAS VEHICLE BEREITS GIBT

       if(this.editVehicle) {
         res = await IbotechService.putOrPostVehicle(this.form, "POST");

       } else {

         this.form.vehicleBarcode = btoa(`${window.location.origin}/qrbooking?value=${this.form.plateNumber}`);
         res = await IbotechService.putOrPostVehicle(this.form, "PUT");
       }

       if(res.status == 202) {
         this.successCreate();
         this.getAllVehiclesFun();

       } else {
         this.error();
       }
     }
  }

  async deleteVehicle(plateNumber: any) {
    const res = await IbotechService.deleteVehicle(plateNumber);

    if(res.status == 200) {

      if(res.data) {

        // Success Message
        this.$bvToast.toast( (this.$t('ibotech_vehicle.deleteSuccess')).toString(), {
          title: (this.$t('ibotech_vehicle.newVehicleForm.successMessageTitle')).toString(),
          autoHideDelay: 1500,
          variant: 'success'
        });

        this.getAllVehiclesFun();

      } else {

        // Info Message
        this.$bvToast.toast((this.$t('ibotech_vehicle.deleteNotAvailableText')).toString(), {
          title: (this.$t('ibotech_vehicle.deleteNotAvailableTitle')).toString(),
          autoHideDelay: 1500,
          variant: 'info'
        });
      }

    } else {
      this.error();

    }
  }

  confirmDeleteVehicle(plateNumber: any) {
    this.$bvModal
      .msgBoxConfirm((this as any).$t("ibotech_vehicle.deleteVehicle"), {
        title: (this as any).$t("inventory.deviceActions.archiveDevice.modalTitle"),
        size: "md",
        buttonSize: "md",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0 bg-light",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: (this as any).$t("utils.alertBox.ok"),
        cancelVariant: "light",
        cancelTitle: (this as any).$t("utils.alertBox.cancel")
      })
      .then((value: boolean) => {
        if (value) {
          this.deleteVehicle(plateNumber);

        } else {
          return false;
        }
      });
  }

  error() {
    this.$bvToast.toast( (this.$t('ibotech_vehicle.newVehicleForm.errorMessageText')).toString(), {
      title: (this.$t('ibotech_vehicle.newVehicleForm.errorMessageTitle')).toString(),
      autoHideDelay: 1500,
      variant: 'danger'
    })
  }

  success() {
    this.$bvToast.toast( (this.$t('ibotech_vehicle.newVehicleForm.successMessageText')).toString(), {
      title: (this.$t('ibotech_vehicle.newVehicleForm.successMessageTitle')).toString(),
      autoHideDelay: 1500,
      variant: 'success'
    })
  }

  infoNoData() {
    this.$bvToast.toast( (this.$t('ibotech_vehicle.infoNoReportDataText')).toString(), {
      title: (this.$t('ibotech_vehicle.infoNoReportDataTitle')).toString(),
      autoHideDelay: 1500,
      variant: 'info'
    })
  }

  successCreate() {

    // Modal Reset
    this.resetVehicleValues()

    // Success Message
    this.success();
  }

  generateQrForBooking(plateNumber: any) {
    this.$router.push({ name: 'QrGenerate', query: { value: plateNumber }  });
  }

  async submitVehicleRecord() {

    if(this.isBookFormValid) {

      // ToDo: Prüfen ob das Kennzeichen schon mehr als 2 Projekte für HEUTE (24h) hat -- Error geben

      // Only need the ID, without description...
      let res = await IbotechService.putVehicleRecord(this.bookForm);

      if(res.status === 202) {
        this.success();
        this.getAllVehiclesFun();
        this.$bvModal.hide("bookLocationToVehicle");

      } else {
        this.error();

      }
    }
  }

  openVehicleRecordsModal(plateNumber: any) {
    this.plateNumber = plateNumber
    this.$bvModal.show('vehicleRecordModal')
  }

  async createVehicleReport() {
    if(this.isVehicleReportFormValid) {
      let from = new Date(`${this.vehicleReportForm.from}T00:00:00`).toISOString();
      let to = new Date(`${this.vehicleReportForm.to}T23:59:59`).toISOString();

      var data = {
        plateNumber: this.plateNumber,
        from: from,
        to: to
      }

      const res = await IbotechService.getVehicleReport(data);

      if (res.status === 200) {
       this.success();

       if(res.data.length > 0) {

         res.data.forEach((item: any) => {
           delete item.location;
         });

         // Definieren der gewünschten Reihenfolge der Schlüssel
         const desiredOrder: string[] = ["id", "locationProjectId", "locationDescription", "vehicle", "registrationTime", "notice", "fulldayBooking"];

        // Sortieren der Daten gemäß der gewünschten Reihenfolge
         const sortedData = res.data.map((item: { [key: string]: any }) => {
           const sortedItem: { [key: string]: any } = {};
           desiredOrder.forEach((key: string) => {
             sortedItem[key] = item[key];
           });
           return sortedItem;
         });

         this.convertToXLSX(sortedData);

       } else {
         this.infoNoData();
       }
      } else {
        return this.error();
      }
    }
  }

  convertToXLSX(data: any) {
    // Holen der Schlüssel aus dem ersten Datenobjekt und Übersetzen der Header
    const keys = Object.keys(data[0]);
    const translatedKeys = keys.map(key => this.$t(`ibotech_location.excelReport.${key}`).toString());

    // Formatieren der Datenzeilen
    const formattedData = this.formattedData(keys, data);

    // Erstellen des Arbeitsblatts
    const ws = XLSX.utils.json_to_sheet(formattedData, { header: translatedKeys });

    // Anpassen der Spaltenbreiten
    const columnWidths = this.calculateColumnWidth(translatedKeys, formattedData);
    ws['!cols'] = columnWidths;

    // Erstellen der Arbeitsmappe
    const wb = XLSX.utils.book_new();

    // Download der Excel-Datei
    this.downloadExcel(wb, ws);
  }

  downloadExcel(workbook: XLSX.WorkBook, worksheet: XLSX.WorkSheet) {
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'export.xlsx');
  }

  calculateColumnWidth(headers: string[], data: { [key: string]: any }[]): { wch: number }[] {
    return headers.map((header) => {
      const columnData = data.map(row => row[header] ? row[header].toString().length : 0);
      const headerLength = header.length;
      const maxLength = Math.max(headerLength, ...columnData);
      return { wch: maxLength + 2 };
    });
  }

  formattedData(keys: string[], data: any[]): { [key: string]: any }[] {
    return data.map((row: any) => {
      const formattedRow = { ...row };
      formattedRow.registrationTime = this.formatDateIntoGermanDate(row.registrationTime);
      formattedRow.fulldayBooking = this.translateFulldayBooking(row.fulldayBooking);
      return keys.reduce((acc: { [key: string]: any }, key: string) => {
        acc[this.translateExportLabel(key)] = formattedRow[key];
        return acc;
      }, {});
    });
  }

  formatDateIntoGermanDate(dateString: string): string {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const formattedTime = date.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${formattedDate} ${formattedTime}`;
  }

  translateFulldayBooking(booking: boolean): string {
    return booking ? 'Ja' : 'Nein';
  }

  translateExportLabel(label: any) {
    return this.$t(`ibotech_location.excelReport.${label}`).toString();
  }

  translateTableLabel(label: string) {
    return this.$t(`ibotech_vehicle.vehicleTableHeadlines.${label}`);
  }

  filterActionHandler(filterAction: string) {
    this.filter = filterAction;
  }

  resetVehicleReportValues() {
    this.vehicleReportForm = {
      from: "",
      to: ""
    }
  }
  get getData() {
    return this.getAllVehiclesData;
  }

  get isFormValid() {
    return Object.entries(this.form).every(([key, value]) => key === 'notice' || value !== '');
  }

  get isBookFormValid() {
    return Object.entries(this.bookForm).every(([key, value]) => key === 'notice' || value !== '');
  }

  get isVehicleReportFormValid() {
    return Object.entries(this.vehicleReportForm).every(([value]) => value !== '' && value !== null);
  }


}
